import Button from "../button";

const Left = () => {
  return (
    <div className="max-w-[660px]">
      <h1
        className="mb-[26px]
        md:mb-[30px]
        lg:mb-[36px]
        xl:mb-10
        text-black
        text-[48px]
        md:text-[52px]
        lap:text-[64px]
        lg:text-[72px]
        xl:text-[80px]
      font-semibold
      leading-[124%]"
      >
        INVESTING IN THE END STATE OF WEB 3.0
      </h1>
      <div>
        {/* <Button link="public" type="primary">Blue7 Fund</Button> */}
      </div>
      <div
        className="mt-[22px]
      block
      lap:hidden"
      >
        <Button link="private" type="secoundary">Private Portfolio</Button>
      </div>
      <div
        className="max-w-[345px]
        h-[1px]
       bg-black
       mt-8
       md:mt-11
       lg:mt-[58px]
       xl:mt-[60px]
       mb-6
       lg:mb-7
       xl:mb-8"
      ></div>
      <p
        className="max-w-[230px]
      lap:max-w-[278px]
      mb-[30px]
      md:pb-[40px]
      lg:pb-[50px]
      xl:pb-[60px]
      text-[18px]
      lap:text-xl
      xl:text-[22px]
    text-black
      leading-[140%]"
      >
        Blue7 invests in early stage founders
        <span className="text-[#0006]"> experimenting with and delivering </span>
        on Web3's potential
      </p>
    </div>
  );
};

export default Left;
