import { Drawer } from "@mui/material";
import Navlink from "./Navlink";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAsyncData } from "../../Redux/actions/blogsAction";

const Sidebar = ({ isSidebar, setIsSidebar }) => {
  const { pathname } = useLocation();

  useEffect(() => setIsSidebar(false), [pathname]);
  const blogData = useSelector((state) => state.blogData);
  const slug = blogData?.blogs?.[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = {
    page: 1,
    limit: 10,
  };
  const handleNavigate = () => {
    navigate(`/${slug.slug}`, {
      state: {
        data: slug,
      },
    });
  };
  useEffect(() => {
    dispatch(getAllAsyncData(data));
  }, []);

  return (
    <Drawer anchor="right" open={isSidebar} onClose={() => setIsSidebar(false)}>
      <nav
        className="mt-[112px]
      flex
      flex-col
      gap-y-6
      p-5"
      >
        <Navlink vertical path="/">
          home
        </Navlink>
        {/* <Navlink vertical path="/public">
          public
        </Navlink> */}
        <Navlink vertical path="/private">
          private
        </Navlink>
        {/* <Navlink vertical path="/b7xlabs">
          B7XLabs
        </Navlink> */}
        <Navlink vertical path={slug?.slug} state={slug}>
          Blog
        </Navlink>
      </nav>
    </Drawer>
  );
};

export default Sidebar;
